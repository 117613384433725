import React, { useEffect } from "react";
import { getLanguages } from "../../store/AsyncMethods/CustomerMethod";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
const MultiLanguageNames = ({
  multiNames,
  name,
  showMultiLangModal,
  setShowMultiLangModal,
  handleChange,
}) => {
  const { allLanguages } = useSelector((state) => state.CustomersReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!allLanguages.length) {
      dispatch(getLanguages());
    }
  }, [dispatch, allLanguages]);
  return (
    <div className="card flex justify-content-center">
      <Dialog
        closable={false}
        ariaCloseIconLabel="false"
        header={t("multi_language_names")}
        visible={showMultiLangModal}
        closeOnEscape={false}
        style={{ width: "20vw" }}
        onHide={() => setShowMultiLangModal(false)}
      >
        <div className="flex flex-col gap-4">
          {allLanguages.map((lang) => (
            <div key={lang.LANG_ID}>
              <span className="flex flex-col gap-2 max-w-4xl w-full">
                <span className="flex gap-2 ml-0">
                  <i className={lang.COUNTRY_FLAG} />
                  <label htmlFor={`multiLang_${lang.LANG_ID}`}>
                    {lang.LANG_NAME}
                  </label>
                </span>
                <InputText
                  id={`multiLang_${lang.LANG_ID}`}
                  name={`multiNames[${lang.LANG_ID}]`}
                  className="max-w-full text-base p-primary-input"
                  value={
                    multiNames[lang.LANG_ID] !== undefined &&
                    multiNames[lang.LANG_ID] !== ""
                      ? multiNames[lang.LANG_ID]
                      : " "
                  }
                  placeholder={name}
                  onChange={handleChange}
                  style={{ direction: lang.LANG_ORIENTATION || "LTR" }}
                />
              </span>
            </div>
          ))}
          <Button
            label={t("submit")}
            type="button"
            icon="pi pi-check"
            className="p-secondary-btn !m-auto"
            onClick={() => setShowMultiLangModal(false)}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default MultiLanguageNames;
