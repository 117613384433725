import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RESET_SPECIFIC_CATEGORIES } from "../../store/Types/CategoryTypes";

import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { toast } from "react-toastify";
import { Divider } from "primereact/divider";
import {
  AddExchangeRate,
  addCurrency,
  getCurrency,
  getEnableCurrency,
  getExchangeRate,
  getspecificCurrency,
  getspecificExchangeRate,
  updateCurrency,
  updateExchangeRate,
} from "../../store/AsyncMethods/CurrencyMethod";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { RESET_SPECIFIC_EXCHANGE_RATE } from "../../store/Types/CurrencyTypes";
import { t } from "i18next";

export default function AddExchangeRates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exchangeRateId } = useParams();
  const { specificExchangeRates, allCurrency } = useSelector(
    (state) => state.CurrencyReducer,
  );

  const calMethodOptions = [
    { name: t("multiply"), value: "*" },
    { name: t("divide"), value: "/" },
  ];

  useEffect(() => {
    if (specificExchangeRates) {
      formik.setValues({
        currencyId: specificExchangeRates.currency_id || "",
        exchangeRate: specificExchangeRates.rates || 0,
        date: new Date(specificExchangeRates.date) || "",
        calulateMethod: specificExchangeRates.calculate_method || "*",
        expireDate: new Date(specificExchangeRates.expire_date) || "",
      });
    }
  }, [specificExchangeRates]);

  const formik = useFormik({
    initialValues: {
      currencyId: "",
      exchangeRate: 0,
      date: "",
      calulateMethod: "*",
      expireDate: "",
    },
    validationSchema: Yup.object({
      currencyId: Yup.number().required("Currency Required"),
      exchangeRate: Yup.number().required("Exchange Rate Required"),
      date: Yup.date().required("Date Required"),
      expireDate: Yup.date().required("Expire Date Required"),
      calulateMethod: Yup.string().required("Calculate Rate Method Required"),
    }),

    onSubmit: async (data) => {
      if (exchangeRateId) {
        dispatch(updateExchangeRate(data, exchangeRateId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch(getEnableCurrency());
            dispatch(getExchangeRate());
            navigate("/manage-exchange-rate");
          }
        });
      } else {
        dispatch(AddExchangeRate(data)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch(getEnableCurrency());
            dispatch(getExchangeRate());
            navigate("/manage-exchange-rate");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (exchangeRateId) {
      dispatch(getspecificExchangeRate(exchangeRateId));
    } else {
      dispatch({ type: RESET_SPECIFIC_EXCHANGE_RATE });
    }
  }, []);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {exchangeRateId ? t("edit") : t("add")} {t("exchange_rate")}
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="currencyId" className="">
                  {t("exchange_currency")}
                </label>
                <Dropdown
                  id="currencyId"
                  name="currencyId"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.currencyId}
                  onChange={formik.handleChange}
                  options={allCurrency}
                  optionLabel="name"
                  optionValue="id"
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-1" },
                    filterInput: { className: "pl-6" },
                  }}
                  placeholder={t("select_exchange_currency")}
                />
              </div>
              {formik.touched?.currencyId && formik.errors?.currencyId && (
                <div className="p-error">{formik.errors?.currencyId}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="exchangeRate" className="">
                  {t("exchange_rate")}
                </label>
                <span className=" w-full">
                  <InputNumber
                    id="exchangeRate"
                    name="exchangeRate"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.exchangeRate}
                    onValueChange={formik.handleChange}
                    minFractionDigits={2}
                    maxFractionDigits={5}
                  />
                </span>
              </div>
              {formik.touched?.exchangeRate && formik.errors?.exchangeRate && (
                <div className="p-error">{formik.errors?.exchangeRate}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="date" className="">
                  {t("date")}
                </label>
                <span className=" w-full">
                  <Calendar
                    id="date"
                    name="date"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    showTime
                    inputClassName="w-full"
                  />
                </span>
              </div>
              {formik.touched?.date && formik.errors?.date && (
                <div className="p-error">{formik.errors?.date}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="calulateMethod" className="">
                  {t("rate_calculation_method")}
                </label>
                <SelectButton
                  name="calulateMethod"
                  id="calulateMethod"
                  options={calMethodOptions}
                  optionLabel="name"
                  optionValue="value"
                  className="flex"
                  pt={{
                    root: { className: "flex" },
                    button: ({ context }) => ({
                      className: context.selected
                        ? "p-primary-highlight-btn w-full text-lg text-center"
                        : "w-full text-lg text-center",
                    }),
                  }}
                  value={formik.values.calulateMethod}
                  onChange={formik.handleChange}
                />
                {formik.touched.calulateMethod &&
                  formik.errors.calulateMethod && (
                    <div className="p-error">
                      {formik.errors.calulateMethod}
                    </div>
                  )}
              </div>
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="expireDate" className="">
                  {t("expire_date")}
                </label>
                <span className=" w-full">
                  <Calendar
                    id="expireDate"
                    name="expireDate"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.expireDate}
                    onChange={formik.handleChange}
                    inputClassName="w-full"
                  />
                </span>
              </div>
              {formik.touched?.expireDate && formik.errors?.expireDate && (
                <div className="p-error">{formik.errors?.expireDate}</div>
              )}
            </div>
          </div>
          <div className="mt-16">
            <div className="flex justify-end gap-4">
              <Button
                label={t("cancel")}
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={() => {
                  formik.resetForm();
                }}
              />
              <Button
                label={t("submit")}
                icon="pi pi-check"
                className="p-secondary-btn"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
