import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrency } from "../../store/AsyncMethods/CurrencyMethod";
import CurrencyTable from "./CurrencyTable";
import { useTranslation } from "react-i18next";
import { TabMenu } from "primereact/tabmenu";
import ExchangeRateTable from "../ExchangeRates/ExchangeRateTable";
import { getExchangeRate } from "../../store/AsyncMethods/CurrencyMethod";
export default function CurrencyMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      label: t("currency"),
      icon: "pi pi-home",
    },
    // {
    //   label: "Exchange Rate",
    //   icon: "pi pi-chart-line",
    // },
  ];

  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    if (user) {
      dispatch(getCurrency());
    }
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(getExchangeRate());
    }
  }, []);
  return (
    <div className="mx-3">
      <div>
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          pt={{
            root: { className: "!overflow-hidden" },
            menu: {
              className: "!bg-transparent !inline-flex ",
            },
            action: { className: "!bg-transparent" },
            label: { className: "!text-primary" },
            icon: { className: "!text-primary" },
          }}
        />
      </div>

      <>
        <div className="flex justify-end gap-2">
          <Button
            label={t("add_exchange_rate")}
            icon="pi pi-plus"
            className="p-primary-btn"
            onClick={() => navigate("/add-exchange-rate")}
          />
          <Button
            label={t("add_currency")}
            icon="pi pi-plus"
            className="p-primary-btn"
            onClick={() => navigate("/add-currency")}
          />
        </div>
        <div className="my-4">
          <CurrencyTable />
        </div>
      </>

      {/* {activeIndex === 0 && (
        <>
          <div className="flex justify-end">
            <Button
              label={t("add_currency")}
              icon="pi pi-plus"
              className="p-primary-btn"
              onClick={() => navigate("/add-currency")}
            />
          </div>
          <div className="my-4">
            <CurrencyTable />
          </div>
        </>
      )}

      {activeIndex === 1 && (
        <>
          <div className="flex justify-end">
            <Button
              label="Add Exchange Rate"
              icon="pi pi-plus"
              className="p-primary-btn"
              onClick={() => navigate("/add-exchange-rate")}
            />
          </div>

          <div className="my-4">
            <ExchangeRateTable />
          </div>
        </>
      )} */}
    </div>
  );
}
