import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { searchProductWithBarcode } from "../../store/AsyncMethods/SalesMethod";
import useScanDetection from "use-scan-detection-react18";
import { useTranslation } from "react-i18next";

export default function AddPurchaseProductForm({
  formik,
  currentProductQuantity,
  setCurrentProductQuantity,
  filterSubCategories,
  validateVarient,
  setValidateVarient,
  editingProduct,
  disabled,
  setEditingProduct,
  setSelectedProductQuantity,
}) {
  const { specificVarients, products } = useSelector(
    (state) => state.ProductReducer
  );
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const idleTimer = useRef(null);
  const [currentProductTitle, setCurrentProductTitle] = useState("");
  const { categories } = useSelector((state) => state.CategoryReducer);

  const [varientDropdownOptions, setVarientDropdownOptions] = useState([]);

  const [searchProductBarcode, setSearchProductBarcode] = useState("");
  const [userCompletedWork, setUserCompletedWork] = useState(false);

  useScanDetection({
    onComplete: (code) => {
      setSearchProductBarcode(code);
      handleSearchProductWithBarcode();
    },
    minLength: 13, // EAN13
  });

  useEffect(() => {
    // If editing an existing product, pre-fill the form
    if (editingProduct) {
      formik.setFieldValue("product.category", editingProduct.category);
      formik.setFieldValue(
        "product.sub_category_id",
        editingProduct.sub_category_id
      );
      // ... other fields
    }
  }, [editingProduct]);

  useEffect(() => {
    // Create options for the PrimeReact Dropdown
    const options = specificVarients.map((variant) => {
      const parsedOptions = JSON.parse(variant.options);

      const optionLabel = parsedOptions
        .map((option) => {
          return `${option} - ${variant[option]?.value || "N/A"}`;
        })
        .join("  |  ");

      return { label: optionLabel, value: variant.varient_id };
    });

    setVarientDropdownOptions(options);
  }, [specificVarients]);

  useEffect(() => {
    if (formik.values.product?.product) {
      const product = categories.find(
        (pro) => pro.id === formik.values.product?.product
      );
      console.log(`product data: ${product.name}`);
      if (product) {
        setCurrentProductTitle(product?.name || "");
        setCurrentProductQuantity(product?.quantity || 0);
        setSelectedProductQuantity(product?.quantity || 0);
        formik.setFieldValue("product.varient", 0);
        formik.setFieldValue("vatPer", product?.vatPer);
        formik.setFieldValue("product.varient", 0);
        formik.setFieldValue("product.salePrice", product.price);
      } else {
        setCurrentProductTitle("");
        setCurrentProductQuantity(0);
        setSelectedProductQuantity(0);
      }
    } else {
      setCurrentProductTitle("");
      setCurrentProductQuantity(0);
      setSelectedProductQuantity(0);
    }
  }, [formik.values.product]);

  useEffect(() => {
    formik.setFieldValue(
      "product.discount",
      ((formik.values?.product?.salePrice *
        formik.values?.product?.discount_value) /
        100) *
        formik.values?.product?.quantity
    );
  }, [formik.values?.product?.quantity]);

  const handleCancel = () => {
    setUserCompletedWork(true);

    setValidateVarient(false);
    setEditingProduct(null);
    formik.setValues({
      ...formik.values,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 0,
        total: 0,
        discount_value: 0,
        is_discount_percentage: true,
      },
      categoryId: "",
      subCategoryId: "",
    });
    setUserCompletedWork(false);
  };

  const updateProductInArray = (updatedProduct) => {
    const index = formik.values.products.findIndex(
      (product) => product.id === editingProduct.id
    );

    if (index !== -1) {
      // Update the values of the object at the found index
      formik.values.products[index] = {
        ...formik.values.products[index],
        ...updatedProduct,
      };
    }

    formik.setValues({
      ...formik.values,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 0,
        total: 0,
        discount_value: 0,
        is_discount_percentage: true,
      },
      categoryId: "",
      subCategoryId: "",
    });

    setEditingProduct(null);
    toast.success("Product Updated Successfully!");
    setUserCompletedWork(false);
  };

  const updateProduct = () => {
    if (editingProduct) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (formik.isValid) {
            updateProductInArray({
              ...formik.values.product,
              category: formik?.values?.product?.category,
              sub_category_id: formik?.values?.product?.sub_category_id,
            });
          }
        } else {
          console.log("Validation errors:", errors);
        }
      });
    }
  };

  const handleAddVarient = () => {
    setValidateVarient(true);
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (formik.isValid) {
          const productsArray = Array.isArray(formik.values.products)
            ? formik.values.products
            : [];

          const newProduct = formik.values.product
            ? {
                ...formik.values.product,
                id: productsArray.length + 1,
                vat_per: formik?.values?.vatPer,
                category: formik?.values?.product?.category,
                sub_category_id: formik?.values?.product?.sub_category_id,
                // vat_amount:
                //   (formik.values?.vatPer / 100) * formik.values.product.total,
                // total_amount_vat:
                //   (formik.values?.vatPer / 100) * formik.values.product.total +
                //   formik.values?.product?.total,
              }
            : {};

          formik.setValues({
            ...formik.values,
            products: [...productsArray, newProduct],
            product: {
              purchasePrice: 0,
              salePrice: 0,
              product: "",
              varient: "",
              discount: 0,
              quantity: 0,
              total: 0,
              discount_value: 0,
              is_discount_percentage: true,
            },
            categoryId: "",
            subCategoryId: "",
          });

          // Reset validation state
          setValidateVarient(false);

          toast.success("Product Added Successfully!");
          setUserCompletedWork(false);
        }
      } else {
        formik.handleSubmit();
        console.log("Validation errors:", errors);
      }
    });
  };

  useEffect(() => {
    // If editing an existing product, pre-fill the form
    if (editingProduct) {
      // Set category and subcategory values
      formik.setFieldValue(
        "categoryId",
        editingProduct.category || editingProduct.product
      );
      formik.setFieldValue(
        "subCategoryId",
        editingProduct.sub_category_id || editingProduct.varient
      );

      formik.setValues((prevValues) => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          category: editingProduct.category || editingProduct.product, // Add this line
          sub_category_id:
            editingProduct.sub_category_id || editingProduct.varient, // Add this line
          purchasePrice: editingProduct?.purchasePrice,
          salePrice: editingProduct?.salePrice,
          product: editingProduct?.product,
          varient: editingProduct?.varient,
          discount: editingProduct?.discount,
          quantity: editingProduct?.quantity,
          total: editingProduct?.total,
          discount_value: editingProduct?.discount_value,
          is_discount_percentage: editingProduct?.is_discount_percentage,
        },
      }));
    }
  }, [editingProduct]);

  useEffect(() => {
    if (
      formik.values?.product?.purchasePrice === 0 &&
      formik.values?.product?.salePrice === 0 &&
      formik.values?.product?.product === "" &&
      formik.values?.product?.varient === "" &&
      formik.values?.product?.discount === 0 &&
      formik.values?.product?.quantity === 0 &&
      formik.values?.product?.total === 0 &&
      formik.values?.product?.discount_value === 0 &&
      formik.values?.product?.is_discount_percentage === true &&
      formik.values?.products?.length !== 0
    ) {
      setValidateVarient(false);
    } else {
      setValidateVarient(true);
    }
  }, [formik.values?.product]);

  const handleSearchProductWithBarcode = () => {
    if (searchProductBarcode.length > 0) {
      dispatch(searchProductWithBarcode(searchProductBarcode)).then((pro) => {
        if (pro) {
          formik.setFieldValue("product.product", pro?.id || "");
          setSearchProductBarcode("");
        }
      });
    }
  };

  // Function to reset idle timer
  const resetIdleTimer = () => {
    clearTimeout(idleTimer.current);
    idleTimer.current = setTimeout(() => {
      if (!userCompletedWork) {
        inputRef.current.focus();
      }
    }, 2500); // Adjust the timeout as needed (e.g., 5000 milliseconds = 5 seconds)
  };

  // Event listeners to reset idle timer on mouse move or key press
  useEffect(() => {
    const handleMouseMove = () => {
      resetIdleTimer();
    };

    const handleKeyPress = () => {
      resetIdleTimer();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  // Effect to focus on input field when component mounts or when userCompletedWork changes
  useEffect(() => {
    if (!userCompletedWork) {
      inputRef.current.focus();
    }
  }, [userCompletedWork]);

  return (
    <>
      <div className="card shadow-md rounded-lg p-4 mt-4">
        <Divider>
          <span className="text-2xl font-bold text-center text-primary mx-1">
            {editingProduct ? t("edit_product") : t("add_product")}
          </span>
        </Divider>

        <div className="px-4 pb-12  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="w-full flex">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                disabled={disabled}
                ref={inputRef}
                placeholder={t("search_using_barcode")}
                className="rounded-r-none pl-12 w-full"
                value={searchProductBarcode}
                onChange={(e) => setSearchProductBarcode(e.target.value)}
                onKeyUpCapture={(e) => {
                  e.preventDefault();
                  if (e.key === "Enter") {
                    handleSearchProductWithBarcode();
                  }
                }}
              />
            </span>
            <Button
              disabled={disabled}
              icon="pi pi-arrow-right"
              className="rounded-l-none p-black-btn"
              type="button"
              onClick={handleSearchProductWithBarcode}
            />
          </div>
        </div>

        <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="">
            <div className="">
              <div>
                <label>{t("category")}</label>
                <Dropdown
                  name="product.category"
                  value={formik.values.product.category}
                  options={categories}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                    formik.setFieldValue("product.category", e.value);
                    // Reset subcategory when category changes
                    // formik.setFieldValue("product.sub_category_id", "");
                  }}
                  placeholder={t("select_category")}
                  className="w-full"
                  disabled={disabled}
                />
              </div>
            </div>
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="product.product" className="">
                {t("article_number")}
              </label>
              <Dropdown
                id="product.product"
                name="product.product"
                className="!w-full text-lg p-primary-input"
                value={formik.values?.product?.product}
                onChange={formik.handleChange}
                options={products}
                optionLabel={(option) => `${option.article_name}`}
                optionValue="id"
                filter
                placeholder={t("select_article_number")}
                showClear
                disabled={disabled || (editingProduct ? true : false)}
                pt={{
                  root: { className: "w-full" },
                  input: { className: "w-full p-primary-input" },
                  filterIcon: { className: "ml-2" },
                  filterInput: { className: "pl-8" },
                }}
              />
            </div> */}
            {/* {formik.touched?.product?.product &&
              formik.errors?.product?.product && (
                <div className="p-error">{formik.errors?.product?.product}</div>
              )} */}
          </div>

          <div className="">
            <div className="">
              <div>
                <label>{t("sub_category")}</label>
                <Dropdown
                  name="product.sub_category_id"
                  value={formik.values.product.sub_category_id}
                  options={filterSubCategories}
                  optionLabel="name"
                  optionValue="id"
                  onChange={formik.handleChange}
                  placeholder={t("select_sub_category")}
                  className="w-full"
                  disabled={!formik.values.product.category || disabled}
                />
              </div>
            </div>

            {/* <div className="flex flex-col gap-2">
              <label htmlFor="name" className="">
                {t("product_title")}
              </label>
              <span className=" w-full">
                <InputText
                  id="name"
                  name="name"
                  className="w-full text-lg p-primary-input"
                  value={currentProductTitle}
                  disabled
                />
              </span>
            </div> */}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.quantity" className="">
                {t("quantity")}
              </label>
              <span className=" w-full">
                <InputNumber
                  disabled={disabled}
                  id="product.quantity"
                  name="product.quantity"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.quantity}
                  onValueChange={formik.handleChange}
                />
              </span>
            </div>
            {formik.touched?.product?.quantity &&
              formik.errors?.product?.quantity && (
                <div className="p-error">
                  {formik.errors?.product?.quantity}
                </div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.salePrice" className="">
                {t("purchase_price")}
              </label>
              <span className=" w-full">
                <InputNumber
                  disabled={disabled}
                  id="product.salePrice"
                  name="product.salePrice"
                  className="w-full text-lg p-primary-input"
                  value={formik?.values?.product?.salePrice}
                  onValueChange={formik.handleChange}
                  prefix={`${enableCurrency?.unit || ""} `}
                />
              </span>
            </div>
            {formik.touched?.product?.salePrice &&
              formik.errors?.product?.salePrice && (
                <div className="p-error">
                  {formik.errors?.product?.salePrice}
                </div>
              )}
          </div>

          {/* <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.discount_value" className="">
                {t("discount")}
              </label>
              <span className=" w-full">
                <InputNumber
                  id="product.discount_value"
                  name="product.discount_value"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.discount_value}
                  onValueChange={formik.handleChange}
                  disabled
                  prefix={
                    formik.values?.product?.is_discount_percentage
                      ? ""
                      : `${enableCurrency?.unit || ""} `
                  }
                />
              </span>
            </div>
            {formik.touched?.product?.discount_value &&
              formik.errors?.product?.discount_value && (
                <div className="p-error">
                  {formik.errors?.product?.discount_value}
                </div>
              )}
          </div> */}

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.discount" className="">
                {t("discount_amount")}
              </label>
              <span className=" w-full">
                <InputNumber
                  disabled={disabled}
                  id="product.discount"
                  name="product.discount"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.discount}
                  onValueChange={formik.handleChange}
                  prefix={`${enableCurrency?.unit || ""} `}
                />
              </span>
            </div>
            {formik.touched?.product?.discount &&
              formik.errors?.product?.discount && (
                <div className="p-error">
                  {formik.errors?.product?.discount}
                </div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.total" className="">
                {t("total_amount")}
              </label>
              <span className=" w-full">
                <InputNumber
                  id="pproduct.total"
                  name="product.total"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.total}
                  onValueChange={formik.handleChange}
                  disabled
                  prefix={`${enableCurrency?.unit || ""} `}
                />
              </span>
            </div>
            {formik.touched?.product?.total &&
              formik.errors?.product?.total && (
                <div className="p-error">{formik.errors?.product?.total}</div>
              )}
          </div>
          {/* <div>
            <div className="flex flex-col gap-2">
              <label htmlFor="vatPer" className="">
                {t("vat_percentage")}
              </label>
              <span className=" w-full">
                <InputText
                  type="number"
                  id="vatPer"
                  name="vatPer"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.vatPer}
                  onChange={formik.handleChange}
                />
              </span>
            </div>
          </div> */}
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              disabled={disabled}
              label={t("cancel")}
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={handleCancel}
            />
            <Button
              label={editingProduct ? t("edit") : t("add")}
              icon="pi pi-check"
              className="p-secondary-btn"
              type="button"
              onClick={editingProduct ? updateProduct : handleAddVarient}
              disabled={disabled || !validateVarient}
            />
          </div>
        </div>
      </div>
    </>
  );
}
