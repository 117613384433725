import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExchangeRateTable from "./ExchangeRateTable";
import { getExchangeRate } from "../../store/AsyncMethods/CurrencyMethod";

export default function ExchangeRatesMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    if (user) {
      dispatch(getExchangeRate());
    }
  }, []);
  return (
    <div className="mx-3">
      <div className="flex justify-end">
        <Button
          label={this("add_exchange_rate")}
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() => navigate("/add-exchange-rate")}
        />
      </div>

      <div className="my-4">
        <ExchangeRateTable />
      </div>
    </div>
  );
}
