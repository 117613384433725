import { useEffect, useRef, useState } from "react";
// import Navbar from "./navbar";
import Sidebar from "./sidebar";
import { ScrollPanel } from "primereact/scrollpanel";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { AiOutlineAppstore } from "react-icons/ai";
import Navbar from "./navbar";
import {
  MdCurrencyExchange,
  MdOutlineCategory,
  MdOutlineManageAccounts,
} from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { TbReportSearch, TbSettings } from "react-icons/tb";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEnableCurrency } from "../store/AsyncMethods/CurrencyMethod";

import { RESET_ERROR, RESET_SUCCESS } from "../store/Types/AuthTypes";
import { toast } from "react-toastify";
import { getVat } from "../store/AsyncMethods/SetupMethod";

function RootLayout({ children }) {
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);

  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const { user, success, error } = useSelector((state) => state.AuthReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getEnableCurrency());
    }
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(getVat());
    }
  }, []);

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch({ type: RESET_SUCCESS });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: RESET_ERROR });
    }
  }, [error]);

  const items =
    user.role_id === 1
      ? [
          {
            name: t("dashboard"),
            path: "/dashboard",
            icon: AiOutlineAppstore,
            showInSidebar: true,
          },
          {
            name: t("manage_categories"),
            path: "/categories",
            icon: MdOutlineCategory,
            showInSidebar: true,
          },
          {
            name: t("add_categories"),
            path: "/add-categories",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_categories")],
          },
          {
            name: t("edit_category"),
            path: "/edit-categories",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: [t("manage_categories")],
          },
          {
            name: t("manage_sub_categories"),
            path: "/sub-categories",
            icon: BiCategoryAlt,
            showInSidebar: true,
          },
          {
            name: t("add_sub_category"),
            path: "/add-sub-categories",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_sub_categories")],
          },
          {
            name: t("edit_sub_category"),
            path: "/edit-sub-categories",
            icon: MdOutlineCategory,
            showInSidebar: false,
            parents: [t("manage_sub_categories")],
          },
          // {
          //   name: t("manage_products"),
          //   path: "/products",
          //   icon: MdOutlineLocalGroceryStore,
          //   showInSidebar: true,
          // },
          // {
          //   name: t("add_product"),
          //   path: "/add-product",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: [t("manage_products")],
          // },
          // {
          //   name: t("edit_product"),
          //   path: "/edit-product",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: [t("manage_products")],
          // },

          {
            name: t("manage_sales"),
            path: "/sales",
            icon: MdOutlineManageAccounts,
            showInSidebar: true,
          },
          {
            name: t("add_sales"),
            path: "/add-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_sales")],
          },
          {
            name: t("edit_sales"),
            path: "/edit-sales",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_sales")],
          },

          {
            name: t("manage_customers"),
            path: "/manage-customers",
            icon: BsFillPersonLinesFill,
            showInSidebar: true,
          },
          {
            name: t("add_customer"),
            path: "/add-customers",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_customers")],
          },
          {
            name: t("edit_customer"),
            path: "/edit-customer",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_customers")],
          },
          {
            name: t("manage_customer_opening_balance"),
            path: "/manage-customer-opening-balance",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_customers")],
          },

          {
            name: t("manage_vendors"),
            path: "/manage-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: true,
          },
          {
            name: t("add_vendor"),
            path: "/add-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },

          {
            name: t("edit_vendor"),
            path: "/edit-vendor",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },
          {
            name: t("manage_vendor_opening_balance"),
            path: "/manage-vendor-opening-balance",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("manage_vendors")],
          },

          {
            name: t("manage_purchase"),
            path: "/purchase",
            icon: MdOutlineManageAccounts,
            showInSidebar: true,
          },
          {
            name: t("add_purchase"),
            path: "/add-purchase",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_purchase")],
          },
          {
            name: t("edit_purchase"),
            path: "/edit-purchase",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_purchase")],
          },
          {
            name: t("edit_purchase"),
            path: "/edit-purchase",
            icon: AiOutlineAppstore,
            showInSidebar: false,
            parents: [t("manage_purchase")],
          },

          {
            name: t("manage_sale_report"),
            path: "/manage-sales-report",
            icon: TbReportSearch,
            showInSidebar: false,
          },
          {
            name: t("manage_purchases_report"),
            path: "/manage-purchases-report",
            icon: TbReportSearch,
            showInSidebar: false,
          },
          {
            name: t("manage_item_report"),
            path: "/manage-item-report",
            icon: TbReportSearch,
            showInSidebar: false,
          },
          {
            name: t("cash_receipt"),
            path: "/manage-cash-receipt",
            icon: TbReportSearch,
            showInSidebar: false,
          },
          {
            name: t("customer_statement_report"),
            path: "/manage-customer-statement",
            icon: TbReportSearch,
            showInSidebar: false,
          },
          {
            name: t("reports"),
            path: null,
            icon: TbReportSearch,
            showInSidebar: true,
            menus: [
              {
                name: t("manage_sale_report"),
                path: "/manage-sales-report",
                icon: TbReportSearch,
                showInSidebar: false,
              },
              {
                name: t("manage_purchases_report"),
                path: "/manage-purchases-report",
                icon: TbReportSearch,
                showInSidebar: false,
              },
              {
                name: t("manage_item_report"),
                path: "/manage-item-report",
                icon: TbReportSearch,
                showInSidebar: false,
              },
              {
                name: t("cash_receipt"),
                path: "/manage-cash-receipt",
                icon: TbReportSearch,
                showInSidebar: false,
              },
              {
                name: t("customer_statement_report"),
                path: "/manage-customer-statement",
                icon: TbReportSearch,
                showInSidebar: false,
              },
              {
                name: t("add_currency"),
                path: "/add-currency",
                icon: AiOutlineAppstore,
                showInSidebar: false,
                parents: [t("manage_currency")],
              },
              {
                name: t("edit_currency"),
                path: "/edit-currency",
                icon: AiOutlineAppstore,
                showInSidebar: false,
                parents: [t("manage_currency")],
              },
              {
                name: t("manage_exchange_rate"),
                path: "/manage-exchange-rate",
                icon: MdCurrencyExchange,
                showInSidebar: false,
              },
              {
                name: t("add_exchange_rate"),
                path: "/add-exchange-rate",
                icon: AiOutlineAppstore,
                showInSidebar: false,
                parents: [t("manage_currency")],
              },
              {
                name: t("edit_exchange_rate"),
                path: "/edit-exchange-rate",
                icon: AiOutlineAppstore,
                showInSidebar: false,
                parents: [t("manage_currency")],
              },
            ],
          },
          {
            name: t("Setup"),
            path: null,
            icon: TbSettings,
            showInSidebar: true,
            menus: [
              {
                name: t("manage_currency"),
                path: "/manage-currency",
                icon: MdCurrencyExchange,
                showInSidebar: true,
              },

              {
                name: t("vat_detail"),
                path: "/setup-vat-detail",
                icon: TbSettings,
                showInSidebar: true,
              },
              {
                name: t("company_detail"),
                path: "/setup-company-detail",
                icon: TbSettings,
                showInSidebar: true,
              },
            ],
          },
          {
            name: t("vat_detail"),
            path: "/setup-vat-detail",
            icon: TbSettings,
            showInSidebar: false,
          },
          {
            name: t("company_detail"),
            path: "/setup-company-detail",
            icon: TbSettings,
            showInSidebar: false,
          },
          {
            name: t("add_vat"),
            path: "/setup-add-vat-detail",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("vat_detail")],
          },
          {
            name: t("edit_vat"),
            path: "/setup-edit-vat-detail",
            icon: BsFillPersonLinesFill,
            showInSidebar: false,
            parents: [t("vat_detail")],
          },
          // {
          //   name: t("manage_users"),
          //   path: "/manage-users",
          //   icon: MdOutlineManageAccounts,
          //   showInSidebar: true,
          // },
          // {
          //   name: t("add_user"),
          //   path: "/add-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: [t("manage_users")],
          // },
          // {
          //   name: t("edit_user"),
          //   path: "/edit-user",
          //   icon: AiOutlineAppstore,
          //   showInSidebar: false,
          //   parents: [t("manage_users")],
          // },
          // {
          //   name: t("sale_report_result"),
          //   path: "/sale-report-result",
          //   icon: TbReportSearch,
          //   parents: [t("manage_sale_report")],
          //   showInSidebar: false,
          // },

          // {
          //   name: t("purchases_report_result"),
          //   path: "/purchas-report-result",
          //   parents: [t("manage_purchases_report")],
          //   icon: TbReportSearch,
          //   showInSidebar: false,
          // },
        ]
      : user.role_id === 2
        ? [
            {
              name: t("dashboard"),
              path: "/dashboard-sales",
              icon: AiOutlineAppstore,
              showInSidebar: true,
            },
            {
              name: t("manage_categories"),
              path: "/categories-sales",
              icon: MdOutlineCategory,
              showInSidebar: true,
            },
            {
              name: t("add_category"),
              path: "/add-categories-sales",
              icon: AiOutlineAppstore,
              showInSidebar: false,
              parents: [t("manage_categories")],
            },
            {
              name: t("edit_category"),
              path: "/edit-categories-sales",
              icon: MdOutlineCategory,
              showInSidebar: false,
              parents: [t("manage_categories")],
            },
            {
              name: t("manage_sub_categories"),
              path: "/sub-categories-sales",
              icon: BiCategoryAlt,
              showInSidebar: true,
            },
            {
              name: t("add_sub_category"),
              path: "/add-sub-categories-sales",
              icon: AiOutlineAppstore,
              showInSidebar: false,
              parents: [t("manage_sub_categories")],
            },
            {
              name: t("edit_sub_category"),
              path: "/edit-sub-categories-sales",
              icon: MdOutlineCategory,
              showInSidebar: false,
              parents: [t("manage_sub_categories")],
            },
            // {
            //   name: t("manage_products"),
            //   path: "/products-sales",
            //   icon: MdOutlineLocalGroceryStore,
            //   showInSidebar: true,
            // },
            // {
            //   name: t("add_product"),
            //   path: "/add-product-sales",
            //   icon: AiOutlineAppstore,
            //   showInSidebar: false,
            //   parents: [t("manage_products")],
            // },
            // {
            //   name: t("edit_product"),
            //   path: "/edit-product-sales",
            //   icon: AiOutlineAppstore,
            //   showInSidebar: false,
            //   parents: [t("manage_products")],
            // },
            // {
            //   name: t("sale_report_result"),
            //   path: "/sale-report-result",
            //   icon: TbReportSearch,
            //   parents: [t("manage_sale_report")],

            //   showInSidebar: false,
            // },
            // {
            //   name: t("purchases_report_result"),
            //   path: "/purchas-report-result",
            //   parents: [t("manage_purchases_report")],
            //   icon: TbReportSearch,
            //   showInSidebar: false,
            // },
            // {
            //   name: t("manage_users"),
            //   path: "/manage-users",
            //   icon: MdOutlineManageAccounts,
            //   showInSidebar: true,
            // },
            // {
            //   name: t("add_user"),
            //   path: "/add-user",
            //   icon: AiOutlineAppstore,
            //   showInSidebar: false,
            //   parents: [t("manage_users")],
            // },
            // {
            //   name: t("edit_user"),
            //   path: "/edit-user",
            //   icon: AiOutlineAppstore,
            //   showInSidebar: false,
            //   parents: [t("manage_users")],
            // },
            {
              name: t("manage_sales"),
              path: "/sales-sales",
              icon: MdOutlineManageAccounts,
              showInSidebar: true,
            },
            {
              name: t("add_sales"),
              path: "/add-sales-sales",
              icon: AiOutlineAppstore,
              showInSidebar: false,
              parents: [t("manage_sales")],
            },
            {
              name: t("edit_sales"),
              path: "/edit-sales-sales",
              icon: AiOutlineAppstore,
              showInSidebar: false,
              parents: [t("manage_sales")],
            },
            {
              name: t("manage_sale_report"),
              path: "/mangage-sales-report",
              icon: TbReportSearch,

              showInSidebar: true,
            },
            {
              name: t("manage_purchases_report"),
              path: "/manage-purchases-report",
              icon: TbReportSearch,
              showInSidebar: true,
            },
          ]
        : [];

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };
  return (
    <div className="flex">
      <Sidebar
        open={open}
        Nav_animation={Nav_animation}
        sidebarRef={sidebarRef}
        setOpen={(value) => setOpen(value)}
        pathname={pathname}
        isTabletMid={isTabletMid}
        items={items}
      />
      <div className="w-full h-screen overflow-auto">
        <Navbar setOpen={(value) => setOpen(value)} items={items} />
        {/* <ScrollPanel
          className=" bg-secondary h-96 "
          pt={{
            barY: {
              className: "bg-secondary",
            },
          }}
        > */}
        <div className="pt-6 mx-auto p-2 md:pr-2">{children}</div>
        {/* </ScrollPanel> */}
      </div>
    </div>
  );
}

export default RootLayout;
