import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { RESET_SPECIFIC_PRODUCT } from "../../store/Types/ProductTypes";
import QRCode from "react-qr-code";
import AddPurchaseForm from "./AddPurchaseForm";
import AddPurchaseProductForm from "./AddPurchaseProductForm";
import AddPurchaseProductTable from "./AddPuchaseProductTable";
import PurchaseCalculation from "./PurchaseCalculation";
import { getVendors } from "../../store/AsyncMethods/VendorMethod";
import {
  getCategory,
  getSubCategory,
} from "../../store/AsyncMethods/CategoryMethod";
import {
  addProduct,
  getArticleNumForProduct,
  getOptionValues,
  getProducts,
  getVarientOption,
  getspecificProduct,
  updateProduct,
  getspecificVarientsByProduct,
  getAllVarients,
} from "../../store/AsyncMethods/ProductMethod";
import {
  addPurchase,
  getPurchase,
  getspecificPurchase,
  updatePurchase,
} from "../../store/AsyncMethods/PurchaseMethod";
import { RESET_SPECIFIC_PURCHASE } from "../../store/Types/PurchaseTypes";

export default function AddPurchase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const { allVat } = useSelector((state) => state.VatReducer);

  const { purchaseId } = useParams();
  const { enableCurrency } = useSelector((state) => state.CurrencyReducer);

  const { error, user } = useSelector((state) => state.AuthReducer);

  const { specificPurchase } = useSelector((state) => state.PurchaseReducer);

  const [validateVarient, setValidateVarient] = useState(true);
  const [isRefund, setIsRefund] = useState(false);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const { subCategories } = useSelector((state) => state.CategoryReducer);

  const [currentProductQuantity, setCurrentProductQuantity] = useState(0);
  const [slectedProductQuantity, setSelectedProductQuantity] = useState(0);

  const [editingProduct, setEditingProduct] = useState(null);

  const editProduct = (product) => {
    setEditingProduct(product);
  };

  useEffect(() => {
    if (specificPurchase) {
      formik.setValues({
        // name: specificPurchase.customer_name || "Customer",
        saleStatus: specificPurchase.status || "invoice",
        // phone: specificPurchase.customer_phone || "03",
        vendor_id: specificPurchase.vendor_id || "03",
        totalPurchase: specificPurchase.total_purchase || 0,
        status: specificPurchase.is_active === 1 ? true : false,
        totalSales: specificPurchase.total_sales || 0,
        totalDiscount: specificPurchase.total_discount || 0,
        vatPer: specificPurchase.vat_per || 0,
        currencyId: specificPurchase.currency_id || "",
        vatAmount: specificPurchase.vat_amount || 0,
        //vatNumber: specificPurchase.vat_number || "",
        totalAmount: specificPurchase.total_amount || 0,
        userId: user.id,
        posted: specificPurchase.is_posted || 0,
        products: specificPurchase.products || [],
        product: {
          purchasePrice: 0,
          salePrice: 0,
          product: "",
          varient: "",
          discount: 0,
          quantity: 0,
          total: 0,
          categoryId: "",
          subCategoryId: "",
          discount_value: 0,
          type: 5,
          custnmbr: 145,
          is_discount_percentage: true,
        },
      });
      if (specificPurchase.is_posted == 1) {
        setIsDisabled(true);
      }
    }
  }, [specificPurchase]);

  const formik = useFormik({
    initialValues: {
      //   name: "Customer",
      //   phone: "03",
      vendor_id: "",
      vatNumber: "",
      status: true,
      saleStatus: "invoice",
      products: [],
      userId: user.id,
      totalPurchase: 0,
      totalSales: 0,
      currencyId: enableCurrency?.currency_id,
      totalDiscount: 0,
      vatPer: 0,
      vatAmount: 0,
      totalAmount: 0,
      posted: 0,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        category: "",
        subCategoryId: "",
        discount: 0,
        quantity: 0,
        total: 0,
        type: 5,
        discount_value: 0,
        is_discount_percentage: true,
      },
    },
    validationSchema: Yup.object({
      //   name: Yup.string().required("Customer Name Required"),
      //   phone: Yup.string().required("Customer Phone Number Required"),
      vendor_id: Yup.number().required("Vendor Required"),
      //   vatNumber: Yup.string()
      //     .required("VAT Number Required")
      //     .matches(
      //       /^3\d{13}3$/,
      //       'VAT Number must be 15 digits long and start and end with "3"'
      //     ),
      saleStatus: Yup.string().required("Sale Status Required"),
      status: Yup.boolean().required("Status Required"),
      // product: validateVarient
      //   ? Yup.object().shape({
      //       category: Yup.number().required("Category is required"),
      //       sub_category_id: Yup.number().required("Subcategory is required"),
      //       purchasePrice: Yup.number().required("Purchase Price is required"),
      //       salePrice: Yup.number().required("Sale Price is required"),
      //       discount: Yup.number().required("Discount is required"),
      //       quantity: Yup.number()
      //         .required("Quantity is required")
      //         .positive("Quantity should be greater than 0"),
      //       total: Yup.number().required("Total is required"),
      //       discount_value: Yup.number().required("Discount is required"),
      //       is_discount_percentage: Yup.boolean(),
      //     })
      //   : Yup.object(),
    }),

    onSubmit: async (data) => {
      if (specificPurchase) {
        dispatch(updatePurchase(data, purchaseId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_PURCHASE });
            dispatch(getPurchase());
            navigate("/purchase");
          }
        });
      } else {
        console.log(data);
        dispatch(addPurchase(data)).then((success) => {
          if (success.success) {
            formik.resetForm();
            dispatch(getPurchase());
            navigate("/invoice-purchase/" + success?.insertSaleId);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (user) {
      dispatch(getCategory());
      dispatch(getSubCategory());
      dispatch(getVarientOption());
      dispatch(getOptionValues());
      // dispatch(getArticleNumForProduct());
    }
  }, []);

  useEffect(() => {
    if (formik.values.product.category) {
      const filter = subCategories.filter(
        (item) => item.category_id === formik.values.product.category
      );
      console.log(`filter: ${filter}`);

      if (filter) {
        setFilterSubCategories(filter);
      }
    }
  }, [formik.values.product.category]);

  useEffect(() => {
    if (formik.values.saleStatus === "refund") {
      // Convert totalSales, totalDiscount, and totalAmount to negative
      formik.setValues({
        ...formik.values,
        totalSales: -formik.values.totalSales,
        totalDiscount: -formik.values.totalDiscount,
        totalAmount: -formik.values.totalAmount,
      });
    } else {
      // Convert totalSales, totalDiscount, and totalAmount to positive
      formik.setValues({
        ...formik.values,
        totalSales: Math.abs(formik.values.totalSales),
        totalDiscount: Math.abs(formik.values.totalDiscount),
        totalAmount: Math.abs(formik.values.totalAmount),
      });
    }
  }, [formik.values.saleStatus]);

  const removeProduct = (productId) => {
    formik.setValues((prevValues) => {
      const updatedProduct = prevValues.products.filter(
        (product) => product.id !== productId
      );
      return { ...prevValues, products: updatedProduct };
    });
  };

  useEffect(() => {
    if (formik.values.product?.product) {
      dispatch(getspecificVarientsByProduct(formik.values.product?.product));
    }
  }, [formik.values.product?.product]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: RESET_ERROR });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      dispatch(getVendors());
      dispatch(getProducts());
      // dispatch(getAllVarients());
    }
  }, []);

  useEffect(() => {
    if (purchaseId) {
      dispatch(getspecificPurchase(purchaseId));
      setValidateVarient(false);
    }
  }, []);

  useEffect(() => {
    if (formik.values.product.salePrice && formik.values.product.quantity) {
      formik.setFieldValue(
        "product.total",
        formik.values.product.salePrice * formik.values.product.quantity -
          formik.values.product.discount || 0
      );
    }
  }, [
    formik.values.product.salePrice,
    formik.values.product.quantity,
    formik.values.product.discount,
  ]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (
      formik?.values?.product?.product &&
      formik?.values?.products?.length > 0
    ) {
      const pro = formik?.values?.products.find(
        (pro) => pro.product === formik?.values?.product?.product
      );

      if (pro) {
        setCurrentProductQuantity(currentProductQuantity - pro.quantity);
      }
    }
  }, [slectedProductQuantity]);
  useEffect(() => {
    if (allVat) {
      formik.setFieldValue(
        "vatPer",
        allVat.find((vat) => vat.type === "purchases")?.percentage || 0
      );
    }
  }, [allVat]);
  useEffect(() => {
    if (enableCurrency) {
      formik.setFieldValue("currencyId", enableCurrency?.currency_id);
    }
  }, [enableCurrency]);

  return (
    <div className="mx-4">
      <form onSubmit={formik.handleSubmit}>
        <AddPurchaseForm
          formik={formik}
          isRefund={isRefund}
          setIsRefund={setIsRefund}
          isDisabled={isDisabled}
        />

        <div>
          <AddPurchaseProductForm
            formik={formik}
            currentProductQuantity={currentProductQuantity}
            setCurrentProductQuantity={setCurrentProductQuantity}
            filterSubCategories={filterSubCategories}
            validateVarient={validateVarient}
            setValidateVarient={setValidateVarient}
            editingProduct={editingProduct}
            disabled={isDisabled}
            setEditingProduct={setEditingProduct}
            setSelectedProductQuantity={setSelectedProductQuantity}
          />
        </div>

        <div className="my-3">
          <AddPurchaseProductTable
            products={formik.values.products}
            category={formik.values.categoryId}
            subCategory={formik.values.subCategoryId}
            removeProduct={removeProduct}
            isDisabled={isDisabled}
            editProduct={editProduct}
          />
        </div>

        {formik.values.products.length > 0 ? (
          <div className="my-3">
            <div className="flex justify-between">
              <div className=""></div>
              <PurchaseCalculation formik={formik} />
            </div>
          </div>
        ) : null}

        <div className="mt-16">
          <div className="flex justify-end gap-4">
            {!isDisabled ? (
              <>
                {" "}
                <Button
                  label={t("cancel")}
                  icon="pi pi-times"
                  className="p-red-btn"
                  type="button"
                  // disabled={showProductForm}
                  onClick={() => {
                    dispatch({ type: RESET_SPECIFIC_PRODUCT });
                    formik.resetForm();
                    setIsRefund(false);
                  }}
                />
                <Button
                  label={specificPurchase ? t("update") : t("submit")}
                  icon="pi pi-check"
                  className="p-secondary-btn"
                  type="submit"
                />{" "}
                <Button
                  label={t("post")}
                  icon="pi pi-check"
                  className="p-primary-btn"
                  type="submit"
                  onClick={() => {
                    formik.values.posted = 1;
                  }}
                />
              </>
            ) : (
              <Button
                label={t("go_back")}
                icon="pi pi-chevron-left"
                severity="secondary"
                type="submit"
                onClick={() => {
                  navigate("/sales");
                }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
