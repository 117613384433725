import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import {
  getCompanyDetails,
  updateCompanyDetails,
} from "../../store/AsyncMethods/CompanyMethod";

export default function CompanyDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyDetails } = useSelector((state) => state.CompanyReducer);

  const navigate = useNavigate();
  useEffect(() => {
    if (companyDetails) {
      formik.setValues({
        name: companyDetails?.name || "",
        name_ar: companyDetails?.name_ar || "",
        tel: companyDetails?.tel || "",
        fax: companyDetails?.fax || "",
        address: companyDetails?.address || "",
        address_ar: companyDetails?.address_ar || "",
        building: companyDetails?.building || "",
        building_ar: companyDetails?.building_ar || "",
        po: companyDetails?.po || "",
        po_ar: companyDetails?.po_ar || "",
        country: companyDetails?.country || "",
        country_ar: companyDetails?.country_ar || "",
        vat: companyDetails?.vat || "",
      });
    }
  }, [companyDetails]);

  const formik = useFormik({
    initialValues: {
      name: companyDetails?.name || "",
      name_ar: companyDetails?.name_ar || "",
      tel: companyDetails?.tel || "",
      fax: companyDetails?.fax || "",
      address: companyDetails?.address || "",
      address_ar: companyDetails?.address_ar || "",
      building: companyDetails?.building || "",
      building_ar: companyDetails?.building_ar || "",
      po: companyDetails?.po || "",
      po_ar: companyDetails?.po_ar || "",
      country: companyDetails?.country || "",
      country_ar: companyDetails?.country_ar || "",
      vat: companyDetails?.vat || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
      name_ar: Yup.string().required("Name Required"),
      tel: Yup.string().required("Phone Required"),
      fax: Yup.string().required("Fax Required"),
      address: Yup.string().required("Address Required"),
      address_ar: Yup.string().required("Address Required"),
      building: Yup.string().required("Building Required"),
      building_ar: Yup.string().required("Building Required"),
      vat: Yup.string()
        .required("VAT Number Required")
        .matches(
          /^3\d{13}3$/,
          'VAT Number must be 15 digits long and start and end with "3"'
        ),

      country: Yup.string().required("Country Required"),
      country_ar: Yup.string().required("Country Required"),
      po: Yup.string().required("P.O Required"),
      po_ar: Yup.string().required("P.O Required"),
    }),

    onSubmit: async (data) => {
      dispatch(updateCompanyDetails(data)).then((success) => {
        if (success) {
          formik.resetForm();
          dispatch(getCompanyDetails());
          navigate("/setup-company-detail");
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, []);

  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-3">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {t("edit")} {t("company_detail")}
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="name" className="">
                  {t("name")}
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="name"
                    name="name"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>

              {formik.touched?.name && formik.errors?.name && (
                <div className="p-error">{formik.errors?.name}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="name_ar" className=" flex  justify-end">
                  {t("اسم الشركة")}
                </label>
                <span className=" w-full">
                  <InputText
                    id="name_ar"
                    name="name_ar"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.name_ar}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.name_ar && formik.errors?.name_ar && (
                <div className="p-error">{formik.errors?.name_ar}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="vat" className="">
                  {t("vat_number")}
                </label>
                <span className=" w-full">
                  <InputText
                    id="vat"
                    name="vat"
                    type="number"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.vat}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.vat && formik.errors?.vat && (
                <div className="p-error">{formik.errors?.vat}</div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div className="card shadow-md rounded-lg p-4 pt-1 mt-3">
            <Divider>
              <span className="text-2xl font-bold text-center text-primary mx-1">
                {t("country_detail")}
              </span>
            </Divider>

            <div className="px-4 gap-4 grid grid-cols-1 lg:grid-cols-2">
              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="country" className="">
                    Country
                  </label>
                  <InputText
                    id="country"
                    name="country"
                    className="!w-full text-lg p-primary-input"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched?.country && formik.errors?.country && (
                  <div className="p-error">{formik.errors?.country}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="country_ar" className="flex justify-end">
                    دولة
                  </label>
                  <InputText
                    id="country_ar"
                    name="country_ar"
                    className="!w-full text-lg p-primary-input"
                    value={formik.values.country_ar}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched?.country_ar && formik.errors?.country_ar && (
                  <div className="p-error">{formik.errors?.country_ar}</div>
                )}
              </div>
            </div>

            <Divider>
              <span className="text-2xl font-bold text-center text-primary mx-1">
                {t("phone_line_detail")}
              </span>
            </Divider>

            <div className="px-4 gap-4 grid grid-cols-1 lg:grid-cols-2">
              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="tel" className="">
                    {t("Tel")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="tel"
                      name="tel"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.tel}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.tel && formik.errors?.tel && (
                  <div className="p-error">{formik.errors?.tel}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="fax" className="">
                    {t("Fax")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="fax"
                      name="fax"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.fax}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.fax && formik.errors?.fax && (
                  <div className="p-error">{formik.errors?.fax}</div>
                )}
              </div>
            </div>
          </div>
          <div className="card shadow-md rounded-lg p-4 pt-1 mt-3">
            <Divider>
              <span className="text-2xl font-bold text-center text-primary mx-1">
                {t("address_detail")}
              </span>
            </Divider>

            <div className="px-4 gap-4 grid grid-cols-1 lg:grid-cols-2">
              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="building" className="">
                    {t("Building")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="building"
                      name="building"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.building}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.building && formik.errors?.building && (
                  <div className="p-error">{formik.errors?.building}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="building_ar" className="flex justify-end">
                    {t("رقم المبنى")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="building_ar"
                      name="building_ar"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.building_ar}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.building_ar && formik.errors?.building_ar && (
                  <div className="p-error">{formik.errors?.building_ar}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="address" className="">
                    {t("address")}*
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="address"
                      name="address"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.address && formik.errors?.address && (
                  <div className="p-error">{formik.errors?.address}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="address_ar" className=" flex justify-end">
                    {t("عنوان الشركة")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="address_ar"
                      name="address_ar"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.address_ar}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.address_ar && formik.errors?.address_ar && (
                  <div className="p-error">{formik.errors?.address_ar}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="po" className="">
                    {t("P.O")}*
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="po"
                      name="o"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.po}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.po && formik.errors?.po && (
                  <div className="p-error">{formik.errors?.po}</div>
                )}
              </div>

              <div className="">
                <div className="flex flex-col gap-2">
                  <label htmlFor="po_ar" className="flex justify-end">
                    {t("صندوق البريد")}
                  </label>
                  <span className=" w-full">
                    <InputText
                      id="po_ar"
                      name="po_ar"
                      className="w-full text-lg p-primary-input"
                      value={formik.values.po_ar}
                      onChange={formik.handleChange}
                    />
                  </span>
                </div>
                {formik.touched?.po_ar && formik.errors?.po_ar && (
                  <div className="p-error">{formik.errors?.po_ar}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              label={t("cancel")}
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                formik.resetForm();
              }}
            />
            <Button
              label={t("submit")}
              type="submit"
              icon="pi pi-check"
              className="p-secondary-btn"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
