import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getspecificVendor } from "../../store/AsyncMethods/VendorMethod";
import { searchProductWithInvoice } from "../../store/AsyncMethods/PurchaseMethod";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
export default function AddPurchaseForm({
  formik,
  isRefund,
  setIsRefund,
  isDisabled,
}) {
  const [searchProductInvoice, setSearchProductInvoice] = useState("");

  const { specificVendor } = useSelector((state) => state.VendorReducer);
  const { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  const { allVendors } = useSelector((state) => state.VendorReducer);

  const { t } = useTranslation();
  const saleStatusOptions = [
    { name: t("invoice"), value: "invoice" },
    { name: t("refund"), value: "refund" },
  ];
  const handleSearchProductWithInvoice = () => {
    if (searchProductInvoice.length > 0) {
      dispatch(searchProductWithInvoice(searchProductInvoice)).then((pro) => {
        if (pro) {
          console.log(pro);

          formik.setValues({
            name: pro.customer_name || "Customer",
            saleStatus: "refund",
            vendor_id: pro.customer_id || "",
            vatNumber: "",
            currencyId: pro.currency_id || 1,
            phone: pro.customer_phone || "03",
            totalPurchase: pro.total_purchase || 0,
            status: pro.is_active === 1 ? true : false,
            totalSales: pro.total_sales || 0,
            totalDiscount: pro.total_discount || 0,
            vatPer: pro.vat_per || 0,
            vatAmount: pro.vat_amount || 0,
            totalAmount: pro.total_amount || 0,
            userId: user.id,
            products: pro.products || [],
            posted: 0,
            product: {
              purchasePrice: 0,
              salePrice: 0,
              product: "",
              varient: "",
              discount: 0,
              quantity: 0,
              total: 0,
              discount_value: 0,
              is_discount_percentage: true,
            },
          });
          setIsRefund(true);
          setSearchProductInvoice("");
        }
      });
    }
  };
  useEffect(() => {
    if (formik.values.customer !== 0) {
      dispatch(getspecificVendor(formik?.values?.vendor_id));
    }
  }, [formik.values.customer]);
  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {purchaseId ? t("edit_vendor") : t("add_vendor")}
        </span>
      </Divider>

      {formik.values.saleStatus === "refund" ? (
        <div className="px-4 pb-12  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="w-full flex">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                placeholder={t("search_using_invoice_hash")}
                className="rounded-r-none pl-12 w-full"
                value={searchProductInvoice}
                onChange={(e) => setSearchProductInvoice(e.target.value)}
                onKeyUpCapture={(e) => {
                  e.preventDefault();
                  if (e.key === "Enter") {
                    handleSearchProductWithInvoice();
                  }
                }}
              />
            </span>
            <Button
              icon="pi pi-arrow-right"
              className="rounded-l-none p-black-btn"
              type="button"
              onClick={handleSearchProductWithInvoice}
            />
          </div>
        </div>
      ) : null}

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="">
              {t("customer_name")}
            </label>
            <span className=" w-full">
              <InputText
                id="name"
                name="name"
                className="w-full text-lg p-primary-input"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.name && formik.errors?.name && (
            <div className="p-error">{formik.errors?.name}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="vendor_id" className="">
              {t("Select Vendor")}
            </label>

            <Dropdown
              disabled={isDisabled}
              id="vendor_id"
              name="vendor_id"
              className="!w-full text-lg p-primary-input"
              value={formik.values.vendor_id}
              onChange={formik.handleChange}
              options={allVendors}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-2" },
                filterInput: { className: "pl-8" },
              }}
            />
          </div>
          {formik.touched?.vendor_id && formik.errors?.vendor_id && (
            <div className="p-error">{formik.errors?.vendor_id}</div>
          )}
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="phone" className="">
              {t("customer_phone_number")}
            </label>
            <span className=" w-full">
              <InputText
                id="phone"
                name="phone"
                className="w-full text-lg p-primary-input"
                value={formik.values?.phone}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.phone && formik.errors?.phone && (
            <div className="p-error">{formik.errors?.phone}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="vatNumber" className="">
              {t("vat_number")}
            </label>
            <span className=" w-full">
              <InputText
                disabled
                id="vatNumber"
                name="vatNumber"
                type="number"
                className="w-full text-lg p-primary-input"
                value={
                  specificVendor?.vat_num
                    ? (formik.values.vatNumber = specificVendor.vat_num)
                    : formik.values.vatNumber
                }
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {/* {formik.touched?.vatNumber && formik.errors?.vatNumber && (
            <div className="p-error">{formik.errors?.vatNumber}</div>
          )} */}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="saleStatus" className="">
              {t("invoice_status")}
            </label>
            <SelectButton
              name="saleStatus"
              id="saleStatus"
              options={saleStatusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              disabled={purchaseId || isRefund ? true : false}
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.saleStatus}
              onChange={formik.handleChange}
            />
            {formik.touched.saleStatus && formik.errors.saleStatus && (
              <div className="p-error">{formik.errors.saleStatus}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
