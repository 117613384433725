import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getProducts } from "../../store/AsyncMethods/ProductMethod";
import { Calendar } from "primereact/calendar";
import ProductReportTable from "./ProductReportTable";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import moment from "moment/moment";

import { MultiSelect } from "primereact/multiselect";
import { motion } from "framer-motion";
export default function ProductReport() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [expandedReport, setExpandedReport] = useState(true);
  const [values, setValues] = useState({});
  const { products } = useSelector((state) => state.ProductReducer);
  const { user } = useSelector((state) => state.AuthReducer);

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  };

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify({})) {
      // check if values have changed
      formik.setValues({
        from_date: new Date(values.from_date),
        to_date: new Date(values.to_date),
        product_id: values.product_id || [],
      });
      setExpandedReport(false);
      setExpanded(true); // re-expand the accordion when values change
    }
  }, [values]);

  const formik = useFormik({
    initialValues: {
      from_date: new Date(new Date().getFullYear(), 0, 1),
      to_date: new Date(),
      product_id: [] || [{ product_id: -1 }],
    },
    validationSchema: Yup.object({
      from_date: Yup.string().required("From Date Required"),
      to_date: Yup.string().required("To Date Required"),
    }),
    onSubmit: async (values) => {
      values.from_date = formatDate(values.from_date);
      values.to_date = formatDate(values.to_date);

      setValues(values);
    },
  });
  useEffect(() => {
    if (user) {
      dispatch(getProducts());
    }
  }, []);
  console.log("allProducts", formik.values);

  return (
    <div className="mx-4 rounded-lg bg-white ">
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider
            onClick={() => setExpandedReport(!expandedReport)}
            className=" hover:cursor-pointer"
          >
            <span className="text-2xl hover:cursor-pointer font-bold text-center text-primary mx-1">
              {t("item_report")}
            </span>
          </Divider>
          <motion.div
            className=" overflow-hidden"
            animate={
              expandedReport
                ? {
                    height: "fit-content",
                  }
                : {
                    height: 0,
                  }
            }
          >
            <>
              <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div className=" ">
                  <div className="flex flex-col  gap-2 p-fluid">
                    <label htmlFor="from_date" className="">
                      {t("from_date")}
                    </label>

                    <Calendar
                      dateFormat="yy/mm/dd"
                      id="from_date"
                      onChange={formik.handleChange}
                      className=" w-full calender p-primary-cld-btn"
                      value={formik.values.from_date}
                      showIcon
                    />
                  </div>
                  {formik.touched?.from_date && formik.errors?.from_date && (
                    <div className="p-error">{formik.errors?.from_date}</div>
                  )}
                </div>

                <div className=" ">
                  <div className="flex flex-col gap-2 p-fluid">
                    <label htmlFor="to_date" className="">
                      {t("to_date")}
                    </label>

                    <Calendar
                      dateFormat="yy/mm/dd"
                      id="to_date"
                      onChange={formik.handleChange}
                      className=" w-full calender p-primary-cld-btn"
                      value={formik.values.to_date}
                      showIcon
                    />
                  </div>
                  {formik.touched?.to_date && formik.errors?.to_date && (
                    <div className="p-error">{formik.errors?.to_date}</div>
                  )}
                </div>

                <div className=" ">
                  <div className="flex flex-col gap-2  ">
                    <label htmlFor="product_id" className="">
                      {t("item_name")}
                    </label>

                    <MultiSelect
                      value={formik.values.product_id}
                      onChange={formik.handleChange}
                      options={products}
                      optionLabel="title"
                      id="id"
                      name="product_id"
                      filter
                      placeholder="select items"
                      className="!w-full p-primary-input"
                      pt={{
                        root: { className: "w-full" },
                        input: { className: "!w-full p-primary-input" },
                        filterContainer: { className: "!w-[80%] relative" },

                        filterIcon: { className: " aboslute right-2" },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-16">
                <div className="flex justify-end gap-4">
                  <Button
                    label={t("cancel")}
                    icon="pi pi-times"
                    className="p-red-btn"
                    type="button"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  />
                  <Button
                    label={t("submit")}
                    icon="pi pi-check"
                    className="p-secondary-btn"
                  />
                </div>
              </div>
            </>{" "}
          </motion.div>
        </div>
      </form>

      {expanded && (
        <div className=" card mt-10 ">
          <div className="flex h-full">
            <button
              onClick={() => setExpandedReport(!expandedReport)}
              className="background-clr-primary  text-white font-bold py-2 px-4 rounded-l"
            >
              <i className="pi pi-angle-double-left"></i>
            </button>
            <div className="h-1 w-full background-clr-primary"></div>
            <button
              onClick={() => setExpandedReport(!expandedReport)}
              className="background-clr-primary text-white font-bold py-2 px-4 rounded-r"
            >
              <i className="pi pi-angle-double-left"></i>
            </button>
          </div>

          <ProductReportTable values={values} />
        </div>
      )}
    </div>
  );
}
