import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import {
  addVat,
  getVat,
  getspecificVat,
  updateVat,
} from "../../store/AsyncMethods/SetupMethod";
import MultiLanguageNames from "../../components/OtherComponents/MultiLanguageNames";

export default function AddVatDetail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { vatId } = useParams();
  const { specificVat } = useSelector((state) => state.VatReducer);

  const [showMultiLangModal, setShowMultiLangModal] = useState(false);
  const allTypes = [
    { type: "sales" },
    { type: "purchases" },
    { type: "adjustment" },
  ];
  const allBased = [{ type: "percentage of sales/purchases" }];
  const allCodes = [
    { type: "S", value: "Standard rate" },
    { type: "E", value: "Exempt from Tax" },
    { type: "Z", value: "Zero-rated goods" },
    { type: "O", value: "Supplies not subject to VAT" },
  ];
  useEffect(() => {
    if (specificVat) {
      formik.setValues({
        description: specificVat.description || "",

        multiNames: ["", "", ""],
        type: specificVat.type || "",
        based: specificVat.based_on || "",
        percentage: specificVat.percentage || 0,
        vatMax: specificVat.vat_max || 0,
        vatMin: specificVat.vat_min || 0,
        code: specificVat.code || "",
        vatIdNumber: specificVat.vat_id_number || "",
      });
    }
  }, [specificVat]);

  const formik = useFormik({
    initialValues: {
      description: "",
      multiNames: [],
      type: "",
      based: "",
      percentage: 0,
      vatMax: 0,
      vatMin: 0,
      code: "",
      vatIdNumber: "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description Required"),
      type: Yup.string().required("Type Required"),
      based: Yup.string().required("Based on Required"),
      code: Yup.string().required("Code Required"),
      vatMin: Yup.string().required("Vat min Required"),
      vatMax: Yup.string().required("Vat min Required"),
      vatIdNumber: Yup.string()
        .required("VAT Number Required")
        .matches(
          /^3\d{13}3$/,
          'VAT Number must be 15 digits long and start and end with "3"',
        ),
      percentage: Yup.string().required("Percentage Required"),
    }),

    onSubmit: async (data) => {
      if (vatId) {
        dispatch(updateVat(data, vatId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch(getVat());
            navigate("/setup-vat-detail");
          }
        });
      } else {
        dispatch(addVat(data)).then((success) => {
          if (success) {
            formik.resetForm();
            //dispatch(getVat());
            navigate("/setup-vat-detail");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (vatId) {
      dispatch(getspecificVat(vatId));
    }
  }, []);

  // console.log("VAT", specificVat);
  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-3">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {vatId ? t("edit") : t("add")} {t("vat")}
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="description" className="">
                  {t("description")}*
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="description"
                    name="description"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />

                  <Button
                    type="button"
                    icon="fi fi-gb"
                    size="large"
                    text
                    rounded
                    className="  scale-100 !mr-auto"
                    onClick={() => {
                      setShowMultiLangModal(true);
                    }}
                  />
                </span>
              </div>

              {formik.touched?.description && formik.errors?.description && (
                <div className="p-error">{formik.errors?.description}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="type" className="">
                  {t("select_type")}
                </label>

                <Dropdown
                  id="type"
                  name="type"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  options={allTypes}
                  optionLabel="type"
                  optionValue="type"
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.type && formik.errors?.type && (
                <div className="p-error">{formik.errors?.type}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="vatIdNumber" className="">
                  {t("vat_id_number")}
                </label>
                <span className=" w-full">
                  <InputText
                    id="vatIdNumber"
                    name="vatIdNumber"
                    type="number"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.vatIdNumber}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.vatIdNumber && formik.errors?.vatIdNumber && (
                <div className="p-error">{formik.errors?.vatIdNumber}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="based" className="">
                  {t("select_based_on")}
                </label>

                <Dropdown
                  id="based"
                  name="based"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.based}
                  onChange={formik.handleChange}
                  options={allBased}
                  optionLabel="type"
                  optionValue="type"
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.based && formik.errors?.based && (
                <div className="p-error">{formik.errors?.based}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="percentage" className="">
                  {t("percentage")}
                </label>
                <span className=" w-full">
                  <InputText
                    id="percentage"
                    name="percentage"
                    type="number"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.percentage}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.percentage && formik.errors?.percentage && (
                <div className="p-error">{formik.errors?.percentage}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="code" className="">
                  {t("select_code")}
                </label>

                <Dropdown
                  id="code"
                  name="code"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  options={allCodes}
                  optionLabel="value"
                  optionValue="type"
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.code && formik.errors?.code && (
                <div className="p-error">{formik.errors?.code}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="vat_amount" className="">
                  {t("vat_amount")}
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="vatMin"
                    name="vatMin"
                    type="number"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.vatMin}
                    onChange={formik.handleChange}
                  />
                  <InputText
                    id="vatMax"
                    name="vatMax"
                    type="number"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.vatMax}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.vatMin && formik.errors?.vatMin && (
                <div className="p-error">{formik.errors?.vatMin}</div>
              )}
              {formik.touched?.vatMax && formik.errors?.vatMax && (
                <div className="p-error">{formik.errors?.vatMax}</div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              label={t("cancel")}
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                formik.resetForm();
              }}
            />
            <Button
              label={t("submit")}
              icon="pi pi-check"
              className="p-secondary-btn"
              type="submit"
            />
          </div>
        </div>
        <MultiLanguageNames
          handleChange={formik.handleChange}
          multiNames={formik.values.multiNames}
          name={formik.values.name}
          showMultiLangModal={showMultiLangModal}
          setShowMultiLangModal={setShowMultiLangModal}
        />
      </form>
    </div>
  );
}
