import React, { useEffect } from "react";
import Invoice from "./Invoice";
import "../../../scss/footer.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getspecificSale } from "../../../store/AsyncMethods/SalesMethod";
import {
  getAllVarients,
  getProducts,
} from "../../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_SALES } from "../../../store/Types/SalesTypes";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { getCompanyDetails } from "../../../store/AsyncMethods/CompanyMethod";

export default function SaleInvoiceMain() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { isPdfDownload } = useParams() || { isPdfDownload: false };

  const dispatch = useDispatch();
  const { saleId } = useParams();
  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
    }
  }, []);
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getAllVarients());
  }, []);
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
    // onBeforePrint: () => {
    //   window.close();
    // },
    onAfterPrint: () => {
      // dispatch({ type: RESET_SPECIFIC_SALES });
      // navigate("/sales");
    },

    // onBeforePrint: () => navigate(-1),
  });
  const handlePdf = () => {
    const element = document.getElementById("receipt-component");

    html2pdf()
      .set({ jsPDF: { unit: "in", format: "a4", orientation: "portrait" } })
      .from(element)
      .save();
  };

  useEffect(() => {
    if (!isPdfDownload) {
      const timer = setTimeout(() => {
        handlePrint();
      }, 1000); // 10 seconds delay in milliseconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount
    } else {
      const timer = setTimeout(() => {
        handlePdf();
        dispatch({ type: RESET_SPECIFIC_SALES });
        navigate("/sales");
      }, 1000); // 10 seconds delay in milliseconds

      return () => clearTimeout(timer);
    }
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCompanyDetails());
  }, []);

  return (
    <div className="px-4">
      <div className="bg-white w-full mx-auto h-screen">
        {/* <div className="text-center mb-3">
          <button onClick={handlePrint}>Print Receipt</button>
        </div> */}
        <div id="receipt-component">
          <Invoice />
        </div>
      </div>
    </div>
  );
}
