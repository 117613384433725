import {
  RESET_VAT,
  RESET_SPECIFIC_VAT,
  SET_VAT,
  SET_SPECIFIC_VAT,
  SET_VAT_LANGUAGES,
  SET_LANGUAGES,
} from "../Types/SetupTypes";

const initialState = {
  allLanguages: [],
  allVat: [],
  vatLanguages: [],
  specificVat: null,
};

const VatReducer = (state = initialState, action) => {
  if (action.type === SET_VAT) {
    return { ...state, allVat: action.payLoad };
  } else if (action.type === SET_VAT_LANGUAGES) {
    return { ...state, vatLanguages: action.payLoad };
  } else if (action.type === SET_LANGUAGES) {
    return { ...state, allLanguages: action.payLoad };
  } else if (action.type === RESET_VAT) {
    return { ...state, allVat: [] };
  } else if (action.type === SET_SPECIFIC_VAT) {
    return { ...state, specificVat: action.payLoad };
  } else if (action.type === RESET_SPECIFIC_VAT) {
    return { ...state, specificVat: null };
  } else {
    return state;
  }
};

export default VatReducer;
