export const SET_PRODUCTS = "SET_PRODUCTS";
export const RESET_PRODUCTS = "RESET_PRODUCTS";
export const SET_SPECIFIC_PRODUCT = "SET_SPECIFIC_PRODUCT";
export const RESET_SPECIFIC_PRODUCT = "RESET_SPECIFIC_PRODUCT";
export const SET_VARIENT_OPTIONS = "SET_VARIENT_OPTIONS";
export const RESET_VARIENT_OPTIONS = "RESET_VARIENT_OPTIONS";
export const SET_OPTION_VALUES = "SET_OPTION_VALUES";
export const RESET_OPTION_VALUES = "RESET_OPTION_VALUES";
export const SET_SPECIFIC_VARIENTS = "SET_SPECIFIC_VARIENTS";
export const RESET_SPECIFIC_VARIENTS = "RESET_SPECIFIC_VARIENTS";
export const SET_ALL_VARIENTS = "SET_ALL_VARIENTS";
export const RESET_ALL_VARIENTS = "RESET_ALL_VARIENTS";
export const SET_ARTICLE_NUM = "SET_ARTICLE_NUM";
export const RESET_ARTICLE_NUM = "RESET_ARTICLE_NUM";
export const SET_PRODUCT_REPORT = "SET_PRODUCT_REPORT";
