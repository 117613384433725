import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RESET_SPECIFIC_CATEGORIES } from "../../store/Types/CategoryTypes";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Icons, toast } from "react-toastify";
import { Divider } from "primereact/divider";

import {
  addCurrency,
  getCurrency,
  getEnableCurrency,
  getspecificCurrency,
  updateCurrency,
} from "../../store/AsyncMethods/CurrencyMethod";
import { SelectButton } from "primereact/selectbutton";
import {
  addCustomers,
  getCustomers,
  getspecificCustomer,
  updateCustomer,
} from "../../store/AsyncMethods/CustomerMethod";
import { Calendar } from "primereact/calendar";
import {
  getSales,
  getspecificSale,
} from "../../store/AsyncMethods/SalesMethod";
import { addCashReceipt } from "../../store/AsyncMethods/CashReceiptMethod";
export default function CashReceipt() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allCustomers } = useSelector((state) => state.CustomersReducer);
  const { allCurrency } = useSelector((state) => state.CurrencyReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allSales } = useSelector((state) => state.SalesReducer);
  const { success, error, user } = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { specificCustomer } = useSelector((state) => state.CustomersReducer);

  const receiptTypes = [
    { name: t("cash"), id: 1 },
    { name: t("return"), id: 2 },
  ];

  useEffect(() => {
    //console.log("specific", specificCustomer);
    if (specificCustomer) {
      formik.setValues({
        ...formik.values,
        name: specificCustomer.name || "",
        balance: specificCustomer.customer_balance || "",
      });
    }
  }, [specificCustomer]);

  useEffect(() => {
    if (specificSale) {
      formik.setValues({
        ...formik.values,
        amount: specificSale.total_amount || "",
      });
    }
  }, [specificSale]);

  const formik = useFormik({
    initialValues: {
      receiptType: "",
      description: "",
      customer: "",
      balance: "",
      amount: "",
      currency: "",
      invoice: "",
      posted: 0,
      name: "",
      date: new Date(),
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
      balance: Yup.string().required("Balance Required"),
      receiptType: Yup.string().required("Type Required"),
      date: Yup.string().required("Transaction Date Required"),
      amount: Yup.string().required("Amount Required"),
      currency: Yup.string().required("Currency Required"),
      invoice: Yup.string().required("Invoice Required"),
    }),

    onSubmit: async (data) => {
      console.log(formik.values);
      dispatch(addCashReceipt(data)).then((success) => {
        if (success) {
          formik.resetForm();
        }
      });
    },
  });
  useEffect(() => {
    if (formik.values.customer)
      dispatch(getspecificCustomer(formik.values.customer));
  }, [formik.values.customer]);

  useEffect(() => {
    if (formik.values.invoice) {
      dispatch(getspecificSale(formik.values.invoice));
    }
  }, [formik.values.invoice]);

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getCurrency());
    dispatch(getSales());
  }, []);
  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-3">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {t("cash_receipt")}
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="receiptType" className="">
                  {t("Receipt Type")}
                </label>

                <Dropdown
                  id="receiptType"
                  name="receiptType"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.receiptType}
                  onChange={formik.handleChange}
                  options={receiptTypes}
                  optionLabel="name"
                  optionValue="id"
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.receiptType && formik.errors?.receiptType && (
                <div className="p-error">{formik.errors?.receiptType}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="currency" className="">
                  {t("Select Currency")}
                </label>

                <Dropdown
                  id="currency"
                  name="currency"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  options={allCurrency}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.currency && formik.errors?.currency && (
                <div className="p-error">{formik.errors?.currency}</div>
              )}
            </div>
            <div className=" ">
              <div className="flex flex-col  gap-2  p-fluid">
                <label htmlFor="date" className="">
                  {t("trans_date")}
                </label>

                <Calendar
                  dateFormat="yy/mm/dd"
                  id="date"
                  onChange={formik.handleChange}
                  className=" w-full calender p-primary-cld-btn"
                  value={formik.values.date}
                  showIcon
                />
              </div>
              {formik.touched?.date && formik.errors?.date && (
                <div className="p-error">{formik.errors?.date}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="description" className="">
                  {t("description")}
                </label>
                <span className=" w-full">
                  <InputText
                    id="description"
                    name="description"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.description && formik.errors?.description && (
                <div className="p-error">{formik.errors?.description}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="customer" className="">
                  {t("Select Customer")}
                </label>

                <Dropdown
                  id="customer"
                  name="customer"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.customer}
                  onChange={formik.handleChange}
                  options={allCustomers}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.customer && formik.errors?.customer && (
                <div className="p-error">{formik.errors?.customer}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="name" className="">
                  {t("customer_name")}*
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="name"
                    name="name"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>

              {formik.touched?.name && formik.errors?.name && (
                <div className="p-error">{formik.errors?.name}</div>
              )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="balance" className="">
                  {t("customer_balance")}*
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="balance"
                    name="balance"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.balance}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>

              {formik.touched?.balance && formik.errors?.balance && (
                <div className="p-error">{formik.errors?.balance}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="invoice" className="">
                  {t("Select Invoice")}
                </label>

                <Dropdown
                  id="invoice"
                  name="invoice"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.invoice}
                  onChange={formik.handleChange}
                  options={allSales}
                  optionLabel={(option) =>
                    `(${option.id}) - ${new Date(option.created_at)}`
                  }
                  optionValue="id"
                  filter
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
              {formik.touched?.invoice && formik.errors?.invoice && (
                <div className="p-error">{formik.errors?.invoice}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="amount" className="">
                  {t("receipt_amount")}*
                </label>
                <span className=" flex gap-2 w-full">
                  <InputText
                    id="amount"
                    name="amount"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>

              {formik.touched?.amount && formik.errors?.amount && (
                <div className="p-error">{formik.errors?.amount}</div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              label={t("cancel")}
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                formik.resetForm();
              }}
            />
            <Button
              label={t("submit")}
              icon="pi pi-check"
              className="p-secondary-btn"
              type="submit"
            />
            <Button
              label={t("post")}
              icon="pi pi-check"
              className="p-primary-btn"
              type="submit"
              onClick={() => {
                formik.values.posted = 1;
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
